// import css from '~/assets/css/pdf.1.css?inline'
import type { HTMLWorker } from 'jspdf'
import jsPDF from 'jspdf'

export const useHTMLToPDF = async (
  filename: string,
  html: string,
): Promise<HTMLWorker> => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 4,
    compress: true,
  })

  const baseUrl = useRuntimeConfig().public.baseURL
  const _html = html
    .replace(/bg-primary/g, '')
    .replace(/text-white/g, 'text-black')
    .replace(/href="\/division\//g, `href="${baseUrl}/division/`)

  const htmlString = /*html*/ `
    <html>
      <head>
        <meta charset='utf-8'>

      </head>
      <body>
        ${_html}
      </body>
    </html>
  `

  return await doc.html(htmlString, {
    callback: (doc) => {
      doc.save(`${filename}.pdf`)
    },
    margin: [25.4, 19, 25.4, 19],
    image: { quality: 0.9, type: 'jpeg' },
    autoPaging: 'text',
    x: 0,
    y: 0,
    width: 190,
    windowWidth: 1000,
    fontFaces: [],
    html2canvas: {
      onclone(doc) {
        doc.head.innerHTML = ''
        const styleElement = doc.createElement('style')
        // styleElement.appendChild(doc.createTextNode(css))
        doc.head.appendChild(styleElement)
      },
    },
  })
}
